<template>
  <form @change="changeForm">
    <div class="row">
      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("LBL_ORGANIZATION_NAME") }}
          {{ $t.translate("LANG_EN") }}</label
        >

        <el-input
          @input="checkOrgName"
          show-word-limit
          :maxlength="$h.getCharLimit().OrgName"
          :disabled="readOnly"
          form-classes="mb-3"
          v-model="profile.OrgNameEN"
          :class="{
            'inp-invalid': $h.isBlank(profile.OrgNameEN),
          }"
        />
      </div>
      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("LBL_ORGANIZATION_NAME") }}
          {{ $t.translate("LANG_CHT") }}</label
        >

        <el-input
          @input="checkOrgName"
          show-word-limit
          :maxlength="$h.getCharLimit().OrgNameCHT"
          :disabled="readOnly"
          form-classes="mb-3"
          v-model="profile.OrgNameCHT"
          :class="{
            'inp-invalid': $h.isBlank(profile.OrgNameCHT),
          }"
        />
      </div>
      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("LBL_ORGANIZATION_NAME") }}
          {{ $t.translate("LANG_CHS") }}</label
        >

        <el-input
          @input="checkOrgName"
          show-word-limit
          :maxlength="$h.getCharLimit().OrgNameCHS"
          :disabled="readOnly"
          form-classes="mb-3"
          v-model="profile.OrgNameCHS"
          :class="{
            'inp-invalid': $h.isBlank(profile.OrgNameCHS),
          }"
        />
      </div>

      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_EN") }}</label
        >

        <el-input
          show-word-limit
          :maxlength="$h.getCharLimit().BranchName - (profile.OrgNameEN.length + 1)"
          :disabled="readOnly || !profile.OrgNameEN"
          form-classes="mb-3"
          v-model="profile.BranchNameEN"
        />
      </div>
      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_CHT") }}</label
        >

        <el-input
          :maxlength="$h.getCharLimit().BranchNameCHT - (profile.OrgNameCHT.length + 1)"
          show-word-limit
          :disabled="readOnly || !profile.OrgNameCHT"
          form-classes="mb-3"
          v-model="profile.BranchNameCHT"
        />
      </div>
      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("FRM_BRANCH_IFANY") }} {{ $t.translate("LANG_CHS") }}</label
        >

        <el-input
          :maxlength="$h.getCharLimit().BranchNameCHS - (profile.OrgNameCHS.length + 1)"
          show-word-limit
          :disabled="readOnly || !profile.OrgNameCHS"
          form-classes="mb-3"
          v-model="profile.BranchNameCHS"
        />
      </div>

      <div class="col-lg-12">
        <label class="form-control-label">{{ $t.translate("FRM_REGION") }}</label>
        <div class="mb-3">
          <el-select
            :placeholder="$t.translate('FRM_SELECT')"
            :disabled="readOnly && !$h.isBlank(profile.Region)"
            class="w-100"
            :class="{ 'inp-invalid': $h.isBlank(profile.Region) }"
            v-model="profile.Region"
            @change="changeForm"
          >
            <el-option
              v-for="item in RegionList"
              :key="item.Value"
              :label="item.Label"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
          {{ $t.translate("LANG_EN") }}</label
        >
        <base-input
          :maxlength="$h.getCharLimit().Address"
          :disabled="readOnly"
          form-classes="mb-3"
          :valid="!$h.isBlank(profile.OrgAddressEN)"
          v-model="profile.OrgAddressEN"
        />
      </div>
      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
          {{ $t.translate("LANG_CHT") }}</label
        >
        <base-input
          :maxlength="$h.getCharLimit().AddressCHT"
          :disabled="readOnly"
          form-classes="mb-3"
          :valid="!$h.isBlank(profile.OrgAddressCHT)"
          v-model="profile.OrgAddressCHT"
        />
      </div>
      <div class="col-lg-12">
        <label class="form-control-label"
          >{{ $t.translate("LBL_ORGANIZATION_ADDRESS") }}
          {{ $t.translate("LANG_CHS") }}</label
        >
        <base-input
          :maxlength="$h.getCharLimit().AddressCHS"
          :disabled="readOnly"
          form-classes="mb-3"
          :valid="!$h.isBlank(profile.OrgAddressCHS)"
          v-model="profile.OrgAddressCHS"
        />
      </div>

      <div class="col-lg-12">
        <div class="d-block d-md-flex justify-content-between">
          <p class="form-control-label mb-0">
            {{ $t.translate("FRM_CORRESPONDING_ADDRESS_IF_DIFFERENT") }}
          </p>
          <div>
            <el-checkbox
              :disabled="viewOnly"
              v-model="profile.IsSameAsOrgAddress"
              @change="resetIfUntick"
            >
              {{ $t.translate("FRM_SAME_AS_ABOVE") }}
            </el-checkbox>
          </div>
        </div>
      </div>
      <template v-if="!profile?.IsSameAsOrgAddress">
        <div class="col-lg-12">
          <label class="form-control-label d-block">{{ $t.translate("LANG_EN") }}</label>
          <base-input
            :maxlength="$h.getCharLimit().Address"
            :disabled="viewOnly"
            form-classes="mb-2"
            v-model="profile.OrgCorAddressEN"
          />
        </div>
        <div class="col-lg-12">
          <label class="form-control-label">{{ $t.translate("LANG_CHT") }}</label>
          <base-input
            :maxlength="$h.getCharLimit().AddressCHT"
            form-classes="mb-2"
            :disabled="!profile.OrgCorAddressEN || viewOnly"
            v-model="profile.OrgCorAddressCHT"
          />
        </div>
        <div class="col-lg-12">
          <label class="form-control-label">{{ $t.translate("LANG_CHS") }}</label>
          <base-input
            :maxlength="$h.getCharLimit().AddressCHS"
            form-classes="mb-3"
            :disabled="!profile.OrgCorAddressEN || viewOnly"
            v-model="profile.OrgCorAddressCHS"
          />
        </div>
      </template>

      <div class="col-lg-12">
        <label class="form-control-label">{{ $t.translate("FRM_INDUSTRY") }}</label>
        <div class="mb-3">
          <el-select
            :placeholder="$t.translate('FRM_SELECT')"
            :disabled="readOnly && !$h.isBlank(profile.IndustryId)"
            class="w-100"
            :class="{ 'inp-invalid': $h.isBlank(profile.IndustryId) }"
            v-model="profile.IndustryId"
            @change="changeForm"
          >
            <el-option
              v-for="item in IndustryList"
              :key="item.IndustryId"
              :label="$h.mapFieldByCC(item, 'Name')"
              :value="item.IndustryId"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="col-md-6">
        <label class="form-control-label">
          {{ $t.translate("FRM_GENERAL_TEL_LINE") }}
        </label>
        <el-input
          :maxlength="$h.getCharLimit().PhoneNumber"
          :disabled="viewOnly"
          v-model="profile.MobileNumber"
          class="mb-3"
          :class="{
            'inp-invalid':
              $h.isBlank(profile.MobileNumber) || !$h.isNumeric(profile.MobileNumber),
          }"
        >
          <template #prepend>
            <CountryCode :disabled="viewOnly" v-model="profile.MobileCode" />
          </template>
        </el-input>
      </div>
      <div class="col-md-6">
        <label class="form-control-label">{{ $t.translate("FRM_FAX") }}</label>
        <base-input
          :maxlength="$h.getCharLimit().Fax"
          :disabled="viewOnly"
          v-model="profile.Fax"
          form-classes="mb-3"
        />
      </div>
      <div class="col-md-6">
        <label class="form-control-label">{{ $t.translate("FRM_GENERAL_EMAIL") }}</label>
        <base-input
          :maxlength="$h.getCharLimit().Email"
          :disabled="viewOnly"
          v-model="profile.GeneralEmail"
          :valid="
            !$h.isBlank(profile.GeneralEmail) && $h.validateEmail(profile.GeneralEmail)
          "
          form-classes="mb-3"
        />

        <!--<div class="form-group mb-3">
          <input
            :value="user?.Email"
            disabled=""
            class="form-control"
            autocomplete="off"
            aria-describedby="addon-right addon-left"
          />
        </div>-->
      </div>
      <div class="col-md-6">
        <label class="form-control-label">
          {{ $t.translate("FRM_WEBSITE") }} ({{ $t.translate("LBL_IF_ANY") }})
        </label>
        <base-input
          :maxlength="$h.getCharLimit().Website"
          :disabled="viewOnly"
          v-model="profile.Website"
          form-classes="mb-3"
        />
      </div>

      <template v-for="(item, index) of profile.Member" :key="index">
        <div class="col-lg-12">
          <div class="contact-info-box-reps mt-3">
            <div class="row">
              <div class="col-lg-12">
                <label class="form-control-label"
                  ><b>{{ $t.translate(item.Label) }}</b></label
                >
              </div>
              <div v-if="TypeOptions(item)?.length" class="col-lg-12">
                <p class="form-control-label mb-1">
                  {{ $t.translate("FRM_IS_SAME_AS") }}
                </p>
                <el-radio-group :disabled="viewOnly" v-model="item.IsSameAsType">
                  <el-radio
                    class="mr-3"
                    v-for="pitem in TypeOptions(item)"
                    :key="pitem.Type"
                    :label="pitem.Type"
                  >
                    {{ $t.translate(pitem.Label) }}
                  </el-radio>
                </el-radio-group>
              </div>

              <template v-if="!item?.IsSameAsType">
                <div class="col-lg-12">
                  <el-radio-group
                    :disabled="viewOnly"
                    class="mt-1"
                    :class="{
                      'inp-invalid': $h.isBlank(item.PersonTitle) && isRequired(item),
                    }"
                    v-model="item.PersonTitle"
                  >
                    <el-radio
                      v-for="pitem in PersonTitleOpts"
                      :key="pitem.value"
                      :label="pitem.value"
                    >
                      {{ $t.translate(pitem.label) }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label">{{ $t.translate("FRM_NAME") }}</label>
                  <el-input
                    :maxlength="$h.getCharLimit().Name"
                    :disabled="viewOnly"
                    v-model="item.Name"
                    :class="{ 'inp-invalid': $h.isBlank(item.Name) && isRequired(item) }"
                    class="mb-2"
                  />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label">{{
                    $t.translate("FRM_POSITION")
                  }}</label>
                  <el-input
                    :maxlength="$h.getCharLimit().Position"
                    :disabled="viewOnly"
                    v-model="item.Position"
                    :class="{
                      'inp-invalid': $h.isBlank(item.Position) && isRequired(item),
                    }"
                    class="mb-2"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">{{
                    $t.translate("FRM_MOBILE_NUMBER")
                  }}</label>
                  <el-input
                    :maxlength="$h.getCharLimit().PhoneNumber"
                    :disabled="viewOnly"
                    v-model="item.MobileNumber"
                    class="mb-2"
                    :class="{
                      'inp-invalid':
                        ($h.isBlank(item.MobileNumber) ||
                          !$h.isNumeric(item.MobileNumber)) &&
                        isRequired(item),
                    }"
                  >
                    <template #prepend>
                      <CountryCode :disabled="viewOnly" v-model="item.MobileCode" />
                    </template>
                  </el-input>
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label">{{
                    $t.translate("FRM_EMAIL")
                  }}</label>
                  <el-input
                    :maxlength="$h.getCharLimit().Email"
                    :disabled="viewOnly"
                    v-model="item.Email"
                    :class="{
                      'inp-invalid':
                        ($h.isBlank(item.Email) && isRequired(item)) ||
                        (!$h.isBlank(item.Email) && !$h.validateEmail(item.Email)),
                    }"
                    type="email"
                    class="mb-2"
                  />
                </div>
              </template>

              <div class="mt-2 col-md-12">
                <el-button :disabled="viewOnly" size="small" @click="clear(item)">{{
                  $t.translate("BTN_CLEAR")
                }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </form>
</template>
<script>
import { useRoute } from "vue-router";
import _ from "lodash";
import { onMounted, reactive, ref, computed } from "vue";
import { helpers } from "@/utils/helpers";
import { get, post, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";
import CountryCode from "@/components/CountryCode";
import { configs } from "@/utils/configs";
import { store } from "@/store";

export default {
  components: {
    CountryCode,
  },
  props: {
    info: Object,
    user: Object,
    readOnly: Boolean,
    viewOnly: Boolean,
  },
  setup(props, context) {
    let { TypeList, RequiredList, RegionList } = store.getters[
      "config/getData"
    ]?.envConf?.organization;
    let { PersonTitleOpts } = store.getters["config/getData"]?.envConf;

    let IndustryList = ref([]);
    let profile = reactive({
      OrgNameEN: "",
      OrgNameCHT: "",
      OrgNameCHS: "",
      BranchNameEN: "",
      BranchNameCHT: "",
      BranchNameCHS: "",
      Region: "",
      OrgAddressEN: "",
      OrgAddressCHT: "",
      OrgAddressCHS: "",
      IsSameAsOrgAddress: false,
      OrgCorAddressEN: "",
      OrgCorAddressCHT: "",
      OrgCorAddressCHS: "",
      IndustryId: "",
      MobileNumber: "",
      MobileCode: "",
      Fax: "",
      GeneralEmail: "",
      Website: "",
      Member: [],
    });

    const loadIndustry = async () => {
      const industryData = await get(`/assessment/industry`);
      IndustryList.value = industryData?.IndustryList || [];
    };

    onMounted(() => {
      loadIndustry();

      if (props?.info) {
        for (let key of Object.keys(profile)) {
          if (key == "IsSameAsOrgAddress") {
            profile[key] = props?.info?.[key] || false;
          } else {
            profile[key] = props?.info?.[key] || (key == "Member" ? [] : "");
          }
        }
      }

      if (!profile.Member.length) {
        profile.Member = _.map(TypeList, (r) => {
          return {
            Label: r.Label,
            Type: r.Type,
            PersonTitle: "",
            Name: "",
            Position: "",
            MobileCode: "",
            MobileNumber: "",
            Email: "",
            IsSameAsType: "",
          };
        });
      } else {
        profile.Member = _.map(TypeList, (r) => {
          let item = _.find(profile.Member, (a) => {
            return a.Type == r.Type;
          });
          if (item) {
            return { ...item, ...{ Label: r.Label } };
          } else {
            return {
              Label: r.Label,
              Type: r.Type,
              PersonTitle: "",
              Name: "",
              Position: "",
              MobileCode: "",
              MobileNumber: "",
              Email: "",
              IsSameAsType: "",
            };
          }
        });
      }
      changeForm();
    });

    let TypeOptions = (item) => {
      // if any other type is same as item type already, item can't be same as any other
      var itemIsLinked = _.find(profile.Member, ["IsSameAsType", item.Type]);
      if (itemIsLinked) return [];

      return _.filter(TypeList, (tp) => {
        // for each option, if corresponding member is same as other type already, item can be same as this option
        var person = _.find(profile.Member, ["Type", tp.Type]);
        return tp.Type != item.Type && !person?.IsSameAsType;
      });
    };

    const resetIfUntick = (bool) => {
      if (!bool) {
        profile.OrgCorAddressEN = "";
        profile.OrgCorAddressCHT = "";
        profile.OrgCorAddressCHS = "";
      }
    };
    const isRequired = (row) => {
      let isEmpty = helpers.isEmptyObject(
        _.pick(row, ["PersonTitle", "Name", "Position", "MobileNumber", "Email"])
      );

      if (!isEmpty) return true;
      return RequiredList.indexOf(row.Type) > -1 ? true : false;
    };
    const clear = (row) => {
      let rows = [
        "PersonTitle",
        "Name",
        "Position",
        "MobileNumber",
        "Email",
        "IsSameAsType",
      ];
      _.each(rows, (f) => {
        row[f] = "";
      });
    };

    let changeForm = () => {
      _.each(profile.Member, function (item) {
        var sameAsIt = _.find(profile.Member, (it) => item.IsSameAsType == it.Type);
        if (sameAsIt) {
          var fields = [
            "PersonTitle",
            "Name",
            "Position",
            "MobileCode",
            "MobileNumber",
            "Email",
          ];
          item = _.assign(item, _.pick(sameAsIt, fields));
        }
      });
      context.emit("update", profile);
    };

    const checkOrgName = () => {
      profile.BranchNameEN =
        profile.OrgNameEN && profile.BranchNameEN ? profile.BranchNameEN : "";
      profile.BranchNameCHS =
        profile.OrgNameCHS && profile.BranchNameCHS ? profile.BranchNameCHS : "";
      profile.BranchNameCHT =
        profile.OrgNameCHT && profile.BranchNameCHT ? profile.BranchNameCHT : "";
    };

    return {
      checkOrgName,
      PersonTitleOpts,
      profile,
      TypeOptions,
      RegionList,
      IndustryList,
      resetIfUntick,
      isRequired,
      clear,
      changeForm,
    };
  },
};
</script>
<style></style>
